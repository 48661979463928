import { Box, BoxProps } from "@biblioteksentralen/js-utils";
import { css } from "@emotion/react";
import { ExtraRichTextBlock, RichTextBlock } from "@libry-content/types";
import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import { BlockContentContextProvider } from "./BlockContentContext";
import { LinkSerializer } from "./LinkSerializer";
import { mapStyles, StyleMap } from "./styleMap";
import { TextGallerySerializer } from "./TextGallerySerializer";
import { TextLinkButtonSerializer } from "./TextLinkButtonSerializer";
import { TextTableSerializer } from "./TextTableSerializer";
import VideoEmbedSerializer from "./VideoEmbedSerializer";

export const richTextStyle = css`
  max-width: 100%;
  > {
    * {
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.5;
    }
    p {
      margin-bottom: 1em;
    }
    h2 {
      &:not(:first-child) {
        margin-top: calc(2vmin + 2rem);
      }
      margin-bottom: 0.25em;
      font-size: 1.5em;
      font-weight: 600;
    }
    h3 {
      margin-top: 1em;
      font-size: 1em;
      font-weight: 600;
    }
    ul,
    ol {
      padding-left: 1.5em;
      margin-bottom: 2.5em;
      ul,
      ol {
        margin-bottom: 0;
      }
      li {
        padding-left: 0.3em;
      }
    }
    ul {
      list-style: disc;
      ul {
        list-style: circle;
      }
    }
    ol {
      list-style: decimal;
    }
  }
`;

const components: Partial<PortableTextReactComponents> = {
  types: {
    table: TextTableSerializer,
    gallery: TextGallerySerializer,
    linkButton: TextLinkButtonSerializer,
    videoEmbed: VideoEmbedSerializer,
  },
  marks: {
    link: LinkSerializer,
  },
};
type Props = {
  blocks?: RichTextBlock | ExtraRichTextBlock;
  styleMap?: StyleMap;
  logInternalLinks?: boolean;
  galleryBackgroundColors?: Record<string, string>;
} & BoxProps;

function BlockContent({ blocks, styleMap, logInternalLinks, galleryBackgroundColors, ...chakraProps }: Props) {
  return (
    <BlockContentContextProvider logInternalLinks={logInternalLinks} galleryBackgroundColors={galleryBackgroundColors}>
      <Box css={richTextStyle} fontSize="lg" {...chakraProps}>
        <PortableText value={mapStyles(styleMap, blocks)} components={components} />
      </Box>
    </BlockContentContextProvider>
  );
}

export default BlockContent;
